// src/services/axios.js
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true, // Enable credentials for CORS
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

api.interceptors.request.use(request => {
    return request;
});

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;